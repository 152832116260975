import React from 'react';

import { MakeRouteWithSubRoutes } from './MakeRouteWithSubRoutes';
import { Switch, Redirect } from 'react-router-dom';
import SignInScreen from '../SignInScreen/SignInScreen';
import LayoutScreen from '../LayoutScreen/LayoutScreen';
import UserList from '../User/List';
import CustomerList from '../Customer/List';
import SellerList from '../Seller/List';
import OrderList from '../Order/List';
import WithdrawList from '../Withdraw/List';
import ProjectReports from '../Reports/ProjectReports';
import ProductCategoryList from '../Product/ProductCategoryList';
import ProductDetails from '../Product/ProductDetails';

const routes = [
  {
    path: '/login',
    component: SignInScreen,
  },

  {
    path: '/page',
    component: LayoutScreen,
    routes: [
      {
        path: '/page/users',
        component: UserList,
      },
      {
        path: '/page/customers',
        component: CustomerList,
      },
      {
        path: '/page/sellers',
        component: SellerList,
      },
      {
        path: '/page/orders',
        component: OrderList,
      },
      {
        path: '/page/withdraws',
        component: WithdrawList,
      },
      {
        path: '/page/Products',
        component: ProductCategoryList,
      },
      {
        path: '/page/ProductDetails',
        component: ProductDetails,
      },
      {
        path: '/page/Reports',
        component: ProjectReports,
      },
    ],
  },
];

export const Router = (props) => {
  return (
    <div>
      <Switch>
        {routes.map((route, index) => (
          <MakeRouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
      <Redirect to={'/login'} />
    </div>
  );
};
