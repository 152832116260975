import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Chip,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  withStyles,
  Typography,
} from '@material-ui/core';
import { api } from '../../helpers/apiHelper';
import OrderDetails from './OrderDetails';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import UserContext from '../../context';

const styles = (theme) => ({
  root: {},
  gridContainer: {
    display: 'flex',
    alignItems: 'baseline',
    paddingLeft: 30,
    justifyContent: 'space-between',
  },
  checkboxControl: {
    display: 'block',
  },
});

export default withStyles(styles)(
  class OrderList extends Component {

    static contextType = UserContext;

    constructor(props) {
      super(props);
      this.state = {
        orders: [],
        customers: [],
        sellers: [],
        // Date range default is from 2 weeks ago to now
        fromDate: moment(new Date()).subtract(14, 'days'),
        toDate: moment(new Date()),
        myOrder: 0,
        loading: true,
      };
    }

    // Fetches orders within the specified date range
    getOrders = async () => {
      this.setState({loading: true});
      const { user } = this.context;
      let payload = {
        dateRange: {
          fromDate: moment(this.state.fromDate)
            .startOf('day')
            .format('YYYY-MM-DD kk:mm:ss'),
          toDate: moment(this.state.toDate)
            .endOf('day')
            .format('YYYY-MM-DD kk:mm:ss'),
        },
      };
      if (this.state.myOrder) {
        payload.sellerId = user.UserId;
      }
      try {
        const res = await api.post('/orders-by-date-range', payload);
        this.setState({ orders: res.data, loading: false });
      } catch (error) {
        console.log("Error fetching orders: " + error);
      }
    };

    componentDidMount() {
      this.getOrders();
    }

    // Sends an order object to be updated to the backend
    onSaveHandler = (data) => {
      let payload = {
        ...data,
      };
      api.put('/orders', payload).then((res) => {
        this.getOrders();
      });
    };

    handleFromDateChange = (date) => {
      this.setState({ fromDate: date });
      console.log('momoment:', moment(date).format('DD-MM-YYYY'));
    };

    handleToDateChange = (date) => {
      this.setState({ toDate: date });
      console.log('momoment:', moment(date).format('DD-MM-YYYY'));
    };

    handleCheckChange = (event) => {
      this.setState({ myOrder: event.target.checked });
    };
    onLoadClick = () => {
      this.getOrders();
    };

    // Deletes an order
    onDeleteConfirmation = ({ OrderId }) => {
      api
        .delete('/orders/', {
          params: {
            orderId: OrderId,
          },
        })
        .then((response) => this.getOrders());
    };

    render() {
      if(this.state.loading){
        return <div/>
      }
      const { classes } = this.props;
      const { user } = this.context;
      return (
        <MaterialTable
          title='Beställningar'
          columns={
            !user.CustomerId
              ? [ // Table fields if user is admin
                  {
                    title: 'Kund',
                    field: 'CustomerName',
                  },
                  { title: 'Användare', field: 'UserName' },
                  {
                    title: 'Datum',
                    field: 'CreationDate',
                    render: (rowData) => (
                      <Typography variant='body' gutterBottom>
                        {moment(rowData.CreationDate).format(
                          'YYYY-MM-DD  kk:mm:ss'
                        )}
                      </Typography>
                    ),
                  },
                  {
                    title: 'Status',
                    field: 'Status',
                    lookup: { 1: 'Skapad', 2: 'Hanterad', 3: 'Raderad' },
                  },
                ]
              : [ // Table fields if user is customer
                  { title: 'Användare', field: 'UserName' },
                  {
                    title: 'Datum',
                    field: 'CreationDate',
                    render: (rowData) => (
                      <Typography variant='body' gutterBottom>
                        {moment(rowData.CreationDate).format(
                          'YYYY-MM-DD  kk:mm:ss'
                        )}
                      </Typography>
                    ),
                  },
                  {
                    title: 'Status',
                    field: 'Status',
                    lookup: { 1: 'Skapad', 2: 'Hanterad', 3: 'Raderad' },
                  },
                  {
                    title: "Fakturerad",
                    render: (rowData) => (
                      // Checkbox for invoiced value, only for customers
                      // Updates the order when clicked
                      <Checkbox
                        disabled={rowData.Status == 1}
                        checked={rowData.Invoiced == 1}
                        style={{width: 0, height: 0}}
                        onChange={async () => {
                          // When clicking the invoiced checkbox, update state
                          await this.setState(prevState => {
                            let newOrders = prevState.orders;
                            newOrders[rowData.tableData.id].Invoiced = 1 - newOrders[rowData.tableData.id].Invoiced; // set to 1 if 0 and vice versa
                            return {orders: newOrders};
                          });
                          // Send update request to backend
                          this.onSaveHandler(this.state.orders[rowData.tableData.id]);
                        }}
                      ></Checkbox>
                    ),
                  }
                ]
          }
          data={this.state.orders}
          actions={[
            {
              icon: 'save',
              tooltip: 'Save Customer',
              onClick: (event, rowData) => alert('You saved ' + rowData.name),
            },
            {
              icon: 'delete',
              tooltip: 'delete Customer',
              onClick: (event, rowData) => this.onDeleteConfirmation(rowData),
            },
          ]}
          options={{
            pageSize: 20,
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.Status === 1 ? '#ff9100' : '#FFF',
            }),
          }}
          components={{
            Action: (props) => {
              if (props.action.icon === 'save') {
                return (
                  <OrderDetails
                    isEdit={true}
                    customer={props.data}
                    data={props.data}
                    sellers={this.state.sellers}
                    onSave={this.onSaveHandler}
                  ></OrderDetails>
                );
              }
              if (props.action.icon === 'delete') {
                return (
                  <DeleteConfirmationDialog
                    title='Bekräftelse'
                    message='Är du säker på att du vill radera beställningen?'
                    actionDisabled={props.data.Status == 2}
                    onConfirmation={(event) =>
                      props.action.onClick(event, props.data)
                    }
                  ></DeleteConfirmationDialog>
                );
              } else return null;
            },

            Toolbar: (props) => (
              <div className={classes.root}>
                <MTableToolbar {...props} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container item xs={9} className={classes.gridContainer}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='yyyy-MM-dd'
                      margin='normal'
                      id='fromDate'
                      label='Från'
                      value={this.state.fromDate}
                      onChange={this.handleFromDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='yyyy-MM-dd'
                      margin='normal'
                      id='toDate'
                      label='Till'
                      value={this.state.toDate}
                      onChange={this.handleToDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    {!user.CustomerId && (
                      <FormControlLabel
                        className={classes.checkboxControl}
                        control={
                          <Checkbox
                            checked={this.state.myOrder == 1}
                            onChange={this.handleCheckChange}
                            name='IsActive'
                            color='primary'
                          />
                        }
                        label='Mina kunder'
                      />
                    )}
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={this.onLoadClick}
                    >
                      Hämta
                    </Button>
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            ),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Ingen information att visa',
            },
            toolbar: {
              searchTooltip: 'Sök',
              searchPlaceholder: 'Sök',
            },
            pagination: {
              labelRowsSelect: 'Rader',
              labelDisplayedRows: '{from}-{to} av {count}',
            },
            header: {
              actions: '',
            },
          }}
        />
      );
    }
  }
);
