import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Fab,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import UserContext from '../../context';
import { api } from '../../helpers/apiHelper';
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  underline: {
    '&::before': {
      "border-bottom": '1px solid rgba(0, 0, 0, 0.15)',
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default withStyles(styles) (class extends Component {
  static contextType = UserContext;
  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  // Creates an object to send to the backend to be updated
  handleSubmit = () => {
    this.handleToggle();
    let order = {
      OrderId: this.props.data.OrderId,
      Status: this.state.order.Status,
      OrderDetails: this.state.order.OrderDetails,
      OrderMessages: this.state.order.OrderMessages,
    };
    this.props.onSave(order);
  };

  constructor(props) {
    super(props);
    this.state = { open: false, order: props.data, status: props.data.Status };
  }

  render() {
    // the status here is only so the checkbox can change without affecting
    // the ability to save edits, the real status is in order.Status
    const { open, order, status } = this.state;
    const { CustomerName, BusinessAddress, SellerId } = this.state;
    const { classes, isEdit, sellers, data } = this.props;
    const { user } = this.context;
    return (
      <div>
        {/* Button for opening the dialog of this order */}
        <IconButton color='secondary' size='small' onClick={this.handleToggle}>
          <Edit />
        </IconButton>

        {/* The dialog displaying information about this order */}
        <Dialog
          onClose={this.handleToggle}
          aria-labelledby='customized-dialog-title'
          open={open}
          fullWidth
          maxWidth='md'
          disableBackdropClick
        >
          <DialogTitle id='customized-dialog-title' onClose={this.handleToggle}>
            {!user.CustomerId
              ? 'Hantera beställningen'
              : 'Beställningsöversikt'}
          </DialogTitle>
          <DialogContent dividers>

            {/* Information about who placed the order */}
            <Grid container>
              <Grid item xs={2}>
                <Typography inline variant='body1' align='left'>
                  Kund:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography inline variant='body1' align='left'>
                  {order.CustomerName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography inline variant='body1' align='left'>
                  Användare:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography inline variant='body1' align='left'>
                  {data.UserName}
                </Typography>
              </Grid>
            </Grid>

            <br />
            {/* Table containing information about which products were ordered and the amount of each */}
            <MaterialTable
              columns={[
                { title: 'Artikelnummer', field: 'ArticleNumber', editable: 'never' },
                { title: 'Beskrivning', field: 'Description', editable: 'never' },
                { title: 'Ytbehandling/Färg', field: 'YtbehandlingFarg', editable: 'never' },
                // The following columns are editable if the order has not been handled yet
                { title: 'Märkning', field: 'ProjectCode', editable: order.Status === 2 ? 'never' : 'always' },
                { title: 'Antal', field: 'Quantity', type: 'tel', editable: order.Status === 2 ? 'never' : 'always' },
              ]}
              data={Array.from(order.OrderDetails)}
              options={{
                paging: false,
                search: false,
                showTitle: false,
                toolbar: false,
              }}
              cellEditable={{
                // When a cell of the order details table is edited
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise(async (resolve, reject) => {
                    // Fetch the product's data
                    const res = await api.get(
                      `/product/product-by-article-number/${rowData.ArticleNumber}`
                    );
                    let antalPerFrp = res.data.AntalEllerFrp;
                    let processed = parseInt(antalPerFrp.replace(/ /g, ""));
                    // Check if the amount has been edited to a correct value
                    if (columnDef.title === "Antal" && (newValue % processed !== 0 || newValue <= 0)) {
                      toast.error(`Välj ett korrekt antal artiklar för denna förpackning (${res.data.AntalEllerFrp} st/förpackning)`)
                      reject();
                    } else if (newValue === "") {
                      toast.error("Märkningen får inte vara tom");
                      reject();
                    } else {
                      // If product was changed so that a message has no products, show warning
                      if (columnDef.field === "ProjectCode") {
                        let messageIndex = order.OrderMessages.findIndex(message => message.ProjectCode === oldValue);
                        let productsWithCode = order.OrderDetails.filter(detail => detail.ProjectCode === oldValue);
                        if (messageIndex >= 0 && productsWithCode.length < 2) {
                          toast.warn(`Du har tagit bort märkningen "${oldValue}" som används för ett meddelande. Du kan ändra meddelandet också`, {autoClose: 10000});
                        }
                      }
                      // If correct amount, change the state of this order to the new value
                      this.setState((prevState) => {
                        let currentOrderDetalis = { ...rowData };
                        currentOrderDetalis[columnDef.field] = newValue;
                        let newOrder = { ...prevState.order };
                        newOrder.OrderDetails[rowData.tableData.id] =
                          currentOrderDetalis;
                        return { order: newOrder };
                      });
                      resolve();
                      this.forceUpdate();
                    }
                  });
                }
              }}
            />
            {/* Messages */}
            {order.OrderMessages.length > 0 && <Box mt={2}>
              <Typography inline variant='body1' align='left'>Meddelanden</Typography>
            </Box>}
            <List>
              {order.OrderMessages.map((message, index) => (
                <ListItem style={{marginTop: (index == 0 ? "0" : "1em")}}>
                  <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Typography style={{fontWeight: 600, textDecoration: "underline"}}>Märkning</Typography>
                    {/* Text field for project code */}
                    <TextField
                      fullWidth
                      value={message.ProjectCode}
                      InputProps={{
                        readOnly: status === 2,
                        classes: {underline: classes.underline},
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(e) => {
                        // Update state
                        let newProjectCode = e.target.value;
                        this.setState((prevState) => {
                          let newOrder = { ...prevState.order };
                          newOrder.OrderMessages.find(m => m.OrderMessageId === message.OrderMessageId).ProjectCode = newProjectCode;
                          return { order: newOrder };
                        });
                      }}
                    />
                    <Typography style={{fontWeight: 600, textDecoration: "underline"}}>Meddelande</Typography>
                    {/* Text field for message */}
                    <TextField
                      fullWidth
                      multiline
                      rowsMax={4}
                      value={message.Message}
                      InputProps={{
                        readOnly: status === 2,
                        classes: {underline: classes.underline},
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(e) => {
                        // Update state
                        let newMessage = e.target.value;
                        this.setState((prevState) => {
                          let newOrder = { ...prevState.order };
                          newOrder.OrderMessages.find(m => m.OrderMessageId === message.OrderMessageId).Message = newMessage;
                          return { order: newOrder };
                        });
                      }}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
            {/* Checkbox for if the order is handled, only accessible for Fastex users */}
            <FormControlLabel control={
              <Checkbox
                checked={status === 2}
                disabled={order.Status != 1 || user.UserType !== 1}
                onChange={() => {
                  this.setState({status: status === 2 ? 1 : 2});
                }}
              />
              } label="Hanterad"/>
          </DialogContent>
          <DialogActions>
            {/* Button for saving edits to an order */}
            <Button
              onClick={async () => {
                // The status of the order in the state must be updated because 
                // status value used for the "Hanterad"-checkbox is only temporary
                await this.setState(prevState => {
                  let newOrder = {...prevState.order};
                  newOrder.Status = status;
                  return {order: newOrder};
                });
                // Send the update request
                this.handleSubmit();
              }}
              color='secondary'
              variant='contained'
              disabled={order.Status != 1}
            >
              Spara
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
})
